import Cookies from 'js-cookie';
import authRequest from '../../api/authRequest';
import {refreshCsrfToken} from '../../api/authApis/authenticate';

let isRefreshing = false;
let failedQueue = [];

const refreshTokenCall = async token => {
	const res = await fetch('/auth/refresh-token', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'x-xsrf-token': Cookies.get('XSRF-TOKEN'),
		},
		body: JSON.stringify({refreshToken: token}),
	});
	return res.json();
};

const processQueue = error => {
	failedQueue.forEach(prom => {
		if (error) {
			prom.reject(error);
		} else {
			prom.resolve();
		}
	});

	failedQueue = [];
};

const appendFailedQueuePromise = originalRequest => {
	return new Promise((resolve, reject) => {
		failedQueue.push({resolve, reject});
	})
		.then(() => {
			return authRequest(originalRequest);
		})
		.catch(err => {
			return Promise.reject(err);
		});
};

const handleRefreshToken = (res, store, originalRequest, resolve) => {
	if (res.message === 'Invalid token') {
		localStorage.removeItem('refresh_token');
		store.dispatch({
			type: 'INITIALIZE_FAIL',
		});
	} else {
		localStorage.setItem('refresh_token', res.refreshToken);
		store.dispatch({
			type: 'INITIALIZE',
			payload: res.response,
		});
	}

	refreshCsrfToken()
		.post()
		.then(() => {
			processQueue(null);
			resolve(authRequest(originalRequest));
		});
};

const refreshTokenPromise = (refreshToken, store, originalRequest) => {
	return new Promise((resolve, reject) => {
		refreshCsrfToken()
			.post()
			.then(() => {
				refreshTokenCall(refreshToken).then(res => {
					handleRefreshToken(res, store, originalRequest, resolve);
				});
			})
			.catch(err => {
				processQueue(err);
				reject(err);
			});
	});
};

const getAuthToken = (refreshToken, store, originalRequest) => {
	if (!isRefreshing) {
		isRefreshing = true;
		return new Promise(resolve => {
			refreshTokenPromise(refreshToken, store, originalRequest).then(result => {
				resetAuthTokenRequest();
				resolve(result);
			});
		});
	} else {
		return appendFailedQueuePromise(originalRequest);
	}
};

const resetAuthTokenRequest = () => {
	isRefreshing = false;
};

const interceptor = store => {
	authRequest.interceptors.response.use(
		response => {
			return response;
		},
		error => {
			if (error.response && error.response.status === 401) {
				// everything works if response body contais status of 401. otherwise else is invoked (at the very bottom of this code)

				const originalRequest = error.config; // gets original request
				const refreshToken = localStorage.getItem('refresh_token'); // gets refresh token
				if (
					error.response.status &&
					error.response.status === 401 &&
					error.response.config &&
					!error.config.__isRetryRequest &&
					refreshToken
				) {
					// checks if refresh token is set, is not retry request, and one more time recheck the status
					originalRequest.__isRetryRequest = true; // sets to true so the request wont be run in an endless cycle
					return getAuthToken(refreshToken, store, originalRequest);
				} else {
					// if token is invalid removes user data and refresh token from localstorage
					refreshCsrfToken()
						.post()
						.then(() => {
							localStorage.removeItem('refresh_token');
							store.dispatch({
								type: 'INITIALIZE_FAIL',
							});
						});
				}
			} else {
				return Promise.reject(error);
			}
		}
	);
};

export default {
	interceptor,
};
