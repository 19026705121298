import {authenticate, refreshCsrfToken, revokeToken} from '../../api/authApis/authenticate';
import {setLanguage} from '../../actions/language';

export const changeUrl = url => async dispatch => {
	dispatch({type: 'CHANGE_PAGE_URL', payload: url});
};

export const signIn = formValues => async dispatch => {
	const response = await authenticate.post('', formValues); // Send auth request

	if (response.data.authenticated) {
		// response has authenticated object set to true or false
		await refreshCsrfToken().post(); // refresh csrf token
		const {user, refreshToken} = response.data; // take auth data (I return user object and refresh token)
		setSession(refreshToken); // save user data to localstorage as json stringify
		dispatch({type: 'SIGN_IN', payload: user}); // dispatch sign_in reducer
	} else {
		setSession(null); // remove everything from localstorage
		dispatch({type: 'SIGN_IN_FAILED', payload: response.data.message});
	}
};

export const signOut = () => async dispatch => {
	await revokeToken({token: localStorage.getItem('refresh_token')}).then(async response => {
		if (response) {
			setSession(null);
			dispatch({type: 'SIGN_OUT'});
			await refreshCsrfToken().post();
		}
	});
};

export const initializeLanguage = () => async dispatch => {
	const locale = localStorage.getItem('language');
	setLanguage(locale, dispatch);
};

export const initialize = (user, refreshToken) => async dispatch => {
	// This method is used when page is being refreshed/entered
	try {
		await refreshCsrfToken().post();

		if (!refreshToken) {
			setSession(null);
		} else {
			setSession(refreshToken);
		}

		dispatch({type: 'INITIALIZE', payload: user});
	} catch {
		await refreshCsrfToken().post();
		setSession(null);
		dispatch({type: 'INITIALIZE_FAIL'});
	}
};

const setSession = token => {
	if (token) {
		localStorage.setItem('refresh_token', token);
	} else {
		localStorage.removeItem('refresh_token');
	}
};
