export * from './UrlSearchParams';
export * from './MarketplaceItemSpecifics';
export * from './Statuses';
export * from './Images';
export * from './ComponentsSizes';
export * from './AjaxStatuses';
export * from './Lists';
export * from './Users';

export const PRODUCT_TYPES = {
	vehicle: 1,
	part: 2,
};

export const WAREHOUSES = {
	vilnius: 1,
};
export const EBAY_SHIPPING_OPTIONS = {
	ukStd: 1,
	euStd: 3,
	wwStd: 5,
	ukExp: 2,
	euExp: 4,
	wwExp: 6,
	courier48: 7,
	collectionInPerson: 8,
	none: 9,
};
