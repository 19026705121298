import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {changeUrl} from '../../actions/authActions';
import {useLocation} from 'react-router';
import {ADMIN_ROLE_ID, PACKER_ROLE_ID, USER_ROLE_ID} from '../../constants';
import NotFound from '../../pages/NotFound';
import MainLayout from '../../layouts/MainLayout';
import PropTypes from 'prop-types';

const PackerAuthGuard = ({
                             isSignedIn,
                             children,
                             changeUrl,
                             userRoleId,
                             pageUrl
                         }) => {
    const location = useLocation();

    if (!isSignedIn) {
        pageUrl !== '/' && changeUrl(location.pathname + location.search);
        return <Redirect to="/login"/>;
    }
    if (userRoleId === PACKER_ROLE_ID || userRoleId === ADMIN_ROLE_ID || userRoleId === USER_ROLE_ID) {
        return children;
    } else {
        return (
            <MainLayout>
                <NotFound/>
            </MainLayout>
        );
    }
};

const mapStateToProps = state => {
    return {
        isSignedIn: state.auth.isSignedIn,
        userRoleId: state.auth.userRoleId,
        pageUrl: state.auth.pageUrl
    };
};

PackerAuthGuard.propTypes = {
    isSignedIn: PropTypes.bool,
    children: PropTypes.object.isRequired,
    changeUrl: PropTypes.func,
    userRoleId: PropTypes.number,
    pageUrl: PropTypes.string,
};

export default connect(mapStateToProps, {changeUrl})(PackerAuthGuard);
