import React, {useState, useRef, useMemo, useCallback} from 'react';
import clsx from 'clsx';
import {useHistory} from 'react-router-dom';
import {Link as RouterLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import {Hidden, Tooltip, Tabs, Tab, IconButton, Toolbar} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Profile from './Profile';
// import Cart from './Cart';
import Notifications from './Notifications';
import BackgroundTasks from './BackgroundTasks';
import {ReactComponent as Close} from '../../images/actions/delete.svg';
import {ReactComponent as LogoIcon} from '../../images/logo/JP_logo_on_dark.svg';
import {ReactComponent as SearchIcon} from '../../images/topAppBar/app_bar_search.svg';
import {ReactComponent as Order} from '../../images/search/search_order.svg';
import {ReactComponent as Part} from '../../images/search/search_part.svg';
import {ReactComponent as Vehicle} from '../../images/search/search_vehicle.svg';
import {toBase64Json} from '../../helpers/global/functions/convert';

const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1,
	},
	drawerWidth: {
		width: 200,
		minWidth: 200,
	},
	appBarProduction: {
		backgroundColor: theme.palette.background.dark,
		position: 'fixed',
		boxShadow: 'none',
	},
	appBarDevelopment: {
		backgroundColor: '#580000',
		position: 'fixed',
		boxShadow: 'none',
	},
	appBarDevelopmentProduction: {
		backgroundColor: 'black',
		position: 'fixed',
		boxShadow: 'none',
	},
	iconHeight: {
		height: '20px',
		fill: theme.palette.primary.main,
	},
	closeIcon: {
		'& line': {
			stroke: theme.palette.primary.main,
		},
	},
	searchIcon: {
		height: '28px',
		marginBottom: 10,
	},
	activeSearchIcon: {
		'& path': {
			fill: theme.palette.primary.main,
		},
	},
	buttonIcon: {
		width: '45px',
		height: '45px',
	},
	logoHeight: {
		height: 30,
		width: 100,
		paddingTop: 3,
	},

	noToolbarPaddings: {
		[theme.breakpoints.down('md')]: {
			paddingLeft: 0,
		},
		paddingRight: 0,
	},
	searchBox: {
		backgroundColor: theme.palette.background.dark,
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		[theme.breakpoints.up('lg')]: {
			width: 'calc(100vw - 200px)',
		},
		animationName: 'increaseDiv',
		animationDuration: '0.5s',
		position: 'absolute',
		right: 0,
	},
	searchGrid: {
		marginRight: 20,
		height: 'inherit',
		marginLeft: 20,
	},
	displayInCenter: {
		display: 'flex',
		justifyContent: 'center',
	},
	inputMargins: {
		marginLeft: 20,
		marginRight: 'auto',
		color: 'white',
		marginTop: 7,
	},
	closeMargins: {
		marginRight: 20,
		marginLeft: 'auto',
	},
	tab: {
		minWidth: 0,
	},
	tabs: {
		minHeight: 0,
	},
	smallOpacity: {
		opacity: 0.2,
	},
	searchingGrid: {
		borderBottom: '1px solid ' + theme.palette.primary.main,
		height: 40,
		marginBottom: 6,
	},
	textAlign: {
		textAlign: 'center',
	},
}));

const TopAppBar = ({onMobileNavOpen, singlePageLayout, p, homeRoute}) => {
	const classes = useStyles();
	const input = useRef();
	const history = useHistory();

	// const [isOpenCart, setIsOpenCart] = useState(false);
	const [isOpenNotifications, setOpenNotifications] = useState(false);

	const [searching, setSearching] = useState(false);
	const [tab, setTab] = useState('parts');

	const handleChange = (_, newValue) => {
		setTab(newValue);
		input.current.focus();
	};

	const [searchText, setSearchText] = useState('');

	const searchTextArray = {
		vehicles: p.tc('search for a vehicle') + '...',
		parts: p.tc('search for a part') + '...',
		orders: p.tc('search for an order') + '...',
	};

	const restoreSearchingText = () => {
		setSearchText('');
		setTab('parts');
	};

	const searchByValue = useCallback(() => {
		history.push(`/${tab}?b=${toBase64Json({query: searchText})}`);
		setSearching(false);
		restoreSearchingText();
	}, [history, searchText, tab]);

	const searchBar = useMemo(() => {
		return (
			<div className={clsx(classes.searchBox)}>
				<Grid container className={classes.searchGrid} alignItems="center">
					<Grid item xs={10} md={11} className={clsx(classes.displayInCenter, classes.searchingGrid)}>
						<Tabs
							variant="fullWidth"
							value={tab}
							onChange={handleChange}
							indicatorColor="primary"
							classes={{root: classes.tabs}}
						>
							<Tab
								value="parts"
								label={
									<Tooltip title={p.tc('parts')}>
										<Part className={clsx({[classes.searchIcon]: true, [classes.activeSearchIcon]: tab === 'parts'})} />
									</Tooltip>
								}
								classes={{root: classes.tab}}
							/>
							<Tab
								value="vehicles"
								label={
									<Tooltip title={p.tc('vehicle')}>
										<Vehicle
											className={clsx({
												[classes.searchIcon]: true,
												[classes.activeSearchIcon]: tab === 'vehicles',
											})}
										/>
									</Tooltip>
								}
								classes={{root: classes.tab}}
							/>
							<Tab
								value="orders"
								label={
									<Tooltip title={p.tc('orders')}>
										<Order
											className={clsx({[classes.searchIcon]: true, [classes.activeSearchIcon]: tab === 'orders'})}
										/>
									</Tooltip>
								}
								classes={{root: classes.tab}}
							/>
						</Tabs>
						<InputBase
							inputRef={input}
							fullWidth
							autoFocus
							placeholder={searchTextArray[tab]}
							inputProps={{'aria-label': 'naked'}}
							className={classes.inputMargins}
							onChange={event => {
								setSearchText(event.target.value);
							}}
							onKeyDown={event => {
								if (event.key === 'Enter') {
									searchByValue();
								}
							}}
							endAdornment={
								<InputAdornment position="end">
									<Tooltip title={p.tc('search')}>
										<div>
											<IconButton
												size="small"
												disabled={searchText === ''}
												onClick={() => {
													searchByValue();
												}}
											>
												<SearchIcon
													className={clsx({
														[classes.iconHeight]: true,
														[classes.smallOpacity]: searchText === '',
													})}
												/>
											</IconButton>
										</div>
									</Tooltip>
								</InputAdornment>
							}
						/>
					</Grid>
					<Grid item xs={2} md={1} className={classes.displayInCenter}>
						<Tooltip title={p.tc('search')}>
							<IconButton
								className={classes.closeMargins}
								onClick={() => {
									setSearching(!searching);
									restoreSearchingText();
								}}
							>
								<Close className={clsx(classes.iconHeight, classes.closeIcon)} />
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
			</div>
		);
	}, [
		classes.activeSearchIcon,
		classes.closeIcon,
		classes.closeMargins,
		classes.displayInCenter,
		classes.iconHeight,
		classes.inputMargins,
		classes.searchBox,
		classes.searchGrid,
		classes.searchIcon,
		classes.searchingGrid,
		classes.smallOpacity,
		classes.tab,
		classes.tabs,
		p,
		searchByValue,
		searchText,
		searchTextArray,
		searching,
		tab,
	]);

	// eslint-disable-next-line no-undef
	const isDev = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
	const isDevProduction = () => window.location.port === '5003' || window.location.port === '5002';
	return (
		<AppBar
			className={
				isDevProduction()
					? classes.appBarDevelopmentProduction
					: isDev()
						? classes.appBarDevelopment
						: classes.appBarProduction
			}
		>
			<Toolbar className={clsx({[classes.noToolbarPaddings]: searching})} variant="dense">
				{!singlePageLayout && !searching && (
					<Hidden lgUp>
						<IconButton edge="start" color="inherit" aria-label="open drawer" onClick={onMobileNavOpen}>
							<MenuIcon />
						</IconButton>
					</Hidden>
				)}

				<Hidden xsDown={!singlePageLayout && !searching} mdDown={!singlePageLayout && searching}>
					<RouterLink to={homeRoute} className={clsx({[classes.drawerWidth]: searching})}>
						<LogoIcon className={classes.logoHeight} />
					</RouterLink>
				</Hidden>

				{!singlePageLayout && searching ? (
					searchBar
				) : (
					<>
						<div className={classes.grow} />
						<div>
							{!singlePageLayout && (
								<>
									<Tooltip title={p.tc('search')}>
										<IconButton
											color="inherit"
											className={classes.buttonIcon}
											onClick={() => {
												setSearching(!searching);
											}}
										>
											<SearchIcon className={classes.iconHeight} />
										</IconButton>
									</Tooltip>
									{/*
							<Cart
								isOpenCart={isOpenCart}
								setIsOpenCart={setIsOpenCart}
								setOpenNotifications={setOpenNotifications}
								p={p}
							/>
							*/}
									<BackgroundTasks p={p} />
									<Notifications
										isOpenNotifications={isOpenNotifications}
										// setIsOpenCart={setIsOpenCart}
										setOpenNotifications={setOpenNotifications}
										p={p}
									/>
								</>
							)}
							<Profile p={p} />
						</div>
					</>
				)}
			</Toolbar>
		</AppBar>
	);
};

TopAppBar.propTypes = {
	onMobileNavOpen: PropTypes.func,
	singlePageLayout: PropTypes.bool,
	p: PropTypes.object,
	homeRoute: PropTypes.string,
};

export default TopAppBar;
