import {useEffect} from 'react';
import {refreshCsrfToken} from '../../api/authApis/authenticate';
import {connect} from 'react-redux';
import {initialize, initializeLanguage} from '../../actions/authActions/';
import authRequest from '../../api/authRequest';
import React from 'react';
import PropTypes from 'prop-types';

const AuthProvider = ({children, initialize, initializeLanguage, isInitialised}) => {
	useEffect(() => {
		initializeLanguage();
	}, [initializeLanguage]);

	useEffect(() => {
		const initializeSignIn = async () => {
			await refreshCsrfToken().post();
			const response = await authRequest.post('/auth/check-auth-token'); // here, thanks to authRequest, if post request fails, interceptor makes
			const refreshToken = localStorage.getItem('refresh_token');
			if (response) {
				initialize(response.data, refreshToken);
			}
		};
		initializeSignIn();
	}, [initialize]);

	if (!isInitialised) {
		return <div />;
	}
	return children;
};
const mapStateToProps = state => {
	return {
		isSignedIn: state.auth.isSignedIn,
		message: state.auth.message,
		isInitialised: state.auth.isInitialised,
	};
};

AuthProvider.propTypes = {
	isInitialised: PropTypes.bool,
	children: PropTypes.array,
	initialize: PropTypes.func,
	initializeLanguage: PropTypes.func,
};

export default connect(mapStateToProps, {initialize, initializeLanguage})(AuthProvider);
