import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	alignCenter: {
		display: 'flex',
		justifyContent: 'center',
	},
	image: {
		[theme.breakpoints.down('sm')]: {
			width: '90%',
		},
		[theme.breakpoints.only('md')]: {
			width: '60%',
		},
		[theme.breakpoints.up('lg')]: {
			width: '50%',
		},
	},
}));

const NotFound = () => {
	const classes = useStyles();
	return (
		<div className={classes.alignCenter}>
			<img
				alt="error"
				src="https://image.freepik.com/free-vector/404-error-background-with-car-wheel-flat-style_23-2147761283.jpg"
				className={classes.image}
			/>
		</div>
	);
};

NotFound.propTypes = {
	error: PropTypes.string,
};

export default NotFound;
