const INITIAL_STATE = [];

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'SET_ITEMS': {
			if (typeof action.payload === 'function') {
				const result = action.payload(state);
				return [...result];
			}
			return (action.payload && [...action.payload]) || [];
		}
		default:
			return state;
	}
};
