const INITIAL_STATE = {
	cart: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'ADD_TO_CART': {
			return {...state, cart: [...state.cart, ...action.payload]};
		}
		case 'SET_CART_ITEMS': {
			return {...state, cart: action.payload};
		}
		default:
			return state;
	}
};
