import React from 'react';
import {Router} from 'react-router-dom';
import {SnackbarProvider} from 'notistack';
import AuthProvider from '../../components/authComponents/AuthProvider';
import {createBrowserHistory} from 'history';
import routes, {renderRoutes} from '../../routes';
import {ThemeProvider, CssBaseline} from '@material-ui/core';
import {createTheme} from '../../theme';
import GlobalStyles from '../../GlobalStyles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-image-lightbox/style.css';

const history = createBrowserHistory();

const theme = createTheme();

const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<Router history={history}>
				<CssBaseline />
				<AuthProvider>
					<GlobalStyles />
					<SnackbarProvider maxSnack={3}>{renderRoutes(routes)}</SnackbarProvider>
				</AuthProvider>
			</Router>
		</ThemeProvider>
	);
};

export default App;
