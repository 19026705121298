import React, {useState, useEffect, useRef, forwardRef} from 'react';
import PropTypes from 'prop-types';
import {HubConnectionBuilder} from '@microsoft/signalr';
import {useCustomSnackbar} from '../../hooks/useCustomSnackbar';
import {makeStyles} from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import {NavLink} from 'react-router-dom';
import RestoreIcon from '@material-ui/icons/Restore';

import {
	IconButton,
	Tooltip,
	Drawer,
	Typography,
	Badge,
	Paper,
	Card,
	CardMedia,
	CardContent,
	Grid,
	CircularProgress,
} from '@material-ui/core';
import {ReactComponent as MessageIconWhite} from '../../images/topAppBar/app_bar_message_white.svg';
import {ReactComponent as MessageIcon} from '../../images/topAppBar/app_bar_message.svg';
import {NO_PHOTO} from '../../constants';
import Push from 'push.js';
import {connect} from 'react-redux';
import {checkToken} from '../../api/authRequest';
const language = localStorage.getItem('language');

const useStyles = makeStyles(theme => ({
	buttonIcon: {
		width: '45px',
		height: '45px',
	},
	badgeColor: {
		marginTop: 7,
		marginRight: 2,
	},
	iconHeight: {
		height: '25px',
		fill: theme.palette.primary.main,
	},
	drawerPaper: {
		width: 350,
		[theme.breakpoints.up('lg')]: {
			top: 48,
			height: 'calc(100% - 48px)',
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},

	logoPaper: {
		[theme.breakpoints.down('xs')]: {
			minHeight: 55,
			height: 55,
		},
		[theme.breakpoints.up('sm')]: {
			minHeight: 63,
			height: 63,
		},
		width: '100%',
		display: 'flex',
		alignItems: 'center',
	},
	messageIcon: {
		marginLeft: 10,
		height: 30,
		width: 30,
		marginBottom: 2,
	},
	noPadding: {
		padding: 0,
	},
	listSecondaryTop: {
		top: 26,
	},
	smallIcon: {
		height: 20,
	},
	closeButton: {
		display: 'none',
	},
	timeAgo: {
		textAlign: 'right',
		margin: 8,
	},
	notificationContent: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'normal',
		display: '-webkit-box',
		'-webkit-line-clamp': 2,
		'-webkit-box-orient': 'vertical',
	},
	cardContent: {
		paddingTop: 8,
		paddingBottom: 0,
		height: 64,
	},
	notificationCardPaper: {
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: theme.palette.action.hover,
		},
		margin: 12,
	},
	clearIcon: {
		position: 'absolute',
		right: 0,
		top: 0,
	},
	overflowEllipsis: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	loadingContainer: {
		minHeight: 37,
		textAlign: 'center',
	},
	closeNotifications: {
		right: 0,
		position: 'absolute',
	},
}));
const getTranslation = (number, key1, key2) => {
	const slicedNumber = number.toString().slice(-2);
	switch (language) {
		case 'ru': {
			if (number === 1 || (slicedNumber[1] === '1' && slicedNumber[0] !== '1')) {
				return key1;
			}
			if (
				slicedNumber[0] === '1' ||
				slicedNumber[0] === '0' ||
				slicedNumber[1] === '0' ||
				parseInt(slicedNumber[1] || slicedNumber[0]) > 4
			) {
				return `${key2}.secondOption`;
			}
			return `${key2}.firstOption`;
		}
		case 'lt': {
			if (number === 1 || (slicedNumber[1] === '1' && slicedNumber[0] !== '1')) {
				return key1;
			}
			if (slicedNumber[0] === '1' || slicedNumber[0] === '0') {
				return `${key2}.firstOption`;
			}
			return `${key2}.secondOption`;
		}
		default: {
			if (number === 1 || (slicedNumber[1] === '1' && slicedNumber[0] !== '1')) {
				return key1;
			}
			return key2;
		}
	}
};
const getTimeAgo = (createdTimeAgo, p) => {
	const message = [];
	if (createdTimeAgo.years > 0) {
		message.push(`${createdTimeAgo.years} ${p.t(getTranslation(createdTimeAgo.years, 'yearago', 'yearsago'))}`);
	}
	if (createdTimeAgo.months > 0) {
		message.push(`${createdTimeAgo.months} ${p.t(getTranslation(createdTimeAgo.months, 'monthago', 'monthsago'))}`);
	}
	if (createdTimeAgo.days > 0) {
		message.push(`${createdTimeAgo.days} ${p.t(getTranslation(createdTimeAgo.days, 'dayago', 'daysago'))}`);
	}
	if (createdTimeAgo.hours > 0 && createdTimeAgo.months === 0 && createdTimeAgo.years === 0) {
		message.push(`${createdTimeAgo.hours} ${p.t(getTranslation(createdTimeAgo.hours, 'hourago', 'hoursago'))}`);
	}
	if (
		createdTimeAgo.minutes > 0 &&
		createdTimeAgo.days === 0 &&
		createdTimeAgo.months === 0 &&
		createdTimeAgo.years === 0 &&
		createdTimeAgo.hours < 10
	) {
		message.push(`${createdTimeAgo.minutes} ${p.t(getTranslation(createdTimeAgo.minutes, 'minuteago', 'minutesago'))}`);
	}
	if (
		createdTimeAgo.seconds > 0 &&
		createdTimeAgo.hours === 0 &&
		createdTimeAgo.days === 0 &&
		createdTimeAgo.months === 0 &&
		createdTimeAgo.years === 0 &&
		createdTimeAgo.minutes < 10
	) {
		message.push(`${createdTimeAgo.seconds} ${p.t(getTranslation(createdTimeAgo.years, 'secondago', 'secondsago'))}`);
	}
	if (message.length > 0) {
		switch (language) {
			case 'lt': {
				message.unshift(p.tc('ago'));
				break;
			}
			default: {
				message.push(p.t('ago'));
				break;
			}
		}
	} else {
		message.push(p.tc('just now'));
	}
	return message.join(' ');
};

const NotificationCard = ({
	item,
	classes,
	p,
	connection,
	showError,
	setNotifications,
	setUnreadNotifications,
	setNotificationIsRead,
}) => {
	const [hovered, setHovered] = useState(false);
	const handleClearClick = e => {
		stopPropagations(e);
		connection.send('DeleteNotification', item.notification.id).catch(err => showError(err));
		setNotifications(prev => prev.filter(i => item.notification.id !== i.notification.id));
		if (!item.notification.isRead) {
			setUnreadNotifications(prev => prev - 1);
		}
	};
	const stopPropagations = e => {
		e.preventDefault();
		e.stopPropagation();
	};

	const onLinkMouseDown = () => {
		if (!item.notification.isRead) {
			setNotificationIsRead(item.notification.id);
		}
	};
	return (
		<NavLink to={item.notification.link} style={{textDecoration: 'none'}} onMouseDown={onLinkMouseDown}>
			<Card
				style={{opacity: item.notification.isRead ? 0.5 : 1}}
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
				className={classes.notificationCardPaper}
			>
				<Grid container justifyContent="flex-start" alignItems="center" style={{position: 'relative'}}>
					{hovered && (
						<IconButton
							className={classes.clearIcon}
							size="small"
							onClick={handleClearClick}
							onMouseDown={stopPropagations}
						>
							<ClearIcon />
						</IconButton>
					)}
					<Grid item xs={4}>
						<CardMedia
							style={{
								height: 0,
								paddingTop: '75%', // 16:9,
								marginTop: '30',
							}}
							image={item.image || NO_PHOTO}
						/>
					</Grid>
					<Grid item xs={8}>
						<CardContent className={classes.cardContent}>
							<Typography variant="subtitle1" className={classes.overflowEllipsis}>
								{p.tc(item.notification.meta)}
							</Typography>
							<Typography variant="subtitle2" className={classes.notificationContent}>
								{item.notification.notificationContent?.toLowerCase() || p.tc(item.notification.name.toLowerCase())}
							</Typography>
							<Typography variant="subtitle2">
								{item.createdByAccount.firstName} {item.createdByAccount.lastName}
							</Typography>
						</CardContent>
						<Grid container direction="row" justifyContent="space-between" alignItems="center">
							<Grid item>
								<Typography variant="subtitle2" className={classes.timeAgo}>
									{item.productItemId ? `${p.tu('id')}: ${item.productItemId}` : ''}
								</Typography>
							</Grid>
							<Grid item>
								<Typography variant="subtitle2" className={classes.timeAgo}>
									{getTimeAgo(item.createdTimeAgo, p)}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Card>
		</NavLink>
	);
};

const browserNotify = (item, p, userId, onClick) => {
	if (item.createdByAccount.id === userId) {
		return;
	}
	Push.create(p.tc(item.notification.meta), {
		tag: item.notification.id,
		body: item.notification.notificationContent || p.tc(item.notification.name.toLowerCase()),
		icon: item.image || NO_PHOTO,
		link: item.notification.link || null,
		onClick: function () {
			window.focus();
			item.notification.link && window.open(item.notification.link, '_blank');
			this.close();
			onClick(item.notification.id);
		},
	});
};
const TooltipComponent = forwardRef(({connected, setOpenNotifications, classes, unreadNotifications, onClick}, ref) => {
	const [tryingToRestore, setTryingToRestore] = useState(false);
	useEffect(() => {
		setTryingToRestore(false);
	}, [connected]);
	if (connected) {
		return (
			<IconButton
				aria-label="new notifications"
				color="inherit"
				className={classes.buttonIcon}
				onClick={() => {
					setOpenNotifications(true);
				}}
				ref={ref}
			>
				<Badge badgeContent={unreadNotifications} color="primary" classes={{badge: classes.badgeColor}}>
					<MessageIconWhite className={classes.iconHeight} />
				</Badge>
			</IconButton>
		);
	}
	if (connected === null || tryingToRestore) {
		return (
			<IconButton ref={ref}>
				<CircularProgress size={15} />
			</IconButton>
		);
	}
	return (
		<IconButton
			ref={ref}
			onClick={() => {
				setTryingToRestore(true);
				onClick();
			}}
		>
			<RestoreIcon size="small" />
		</IconButton>
	);
});

const Notifications = ({isOpenNotifications, setOpenNotifications, p, userId}) => {
	const classes = useStyles();
	const [notifications, setNotifications] = useState([]);
	const [connection, setConnection] = useState(null);
	const [loading, setLoading] = useState(false);
	const [connected, setConnected] = useState(null);
	const [page, setPage] = useState(1);
	const [getAllUserNotificationsEnabled, setGetAllUserNotificationsEnabled] = useState(true);
	const [unreadNotifications, setUnreadNotifications] = useState(0);
	const intervalRef = useRef(null);
	const initialDrawerOpenRequestSent = useRef(false);
	const {showError} = useCustomSnackbar();

	const setNotificationIsRead = React.useCallback(
		notificationId => {
			connection.send('SetNotificationIsRead', notificationId).catch(err => showError(err));
			setNotifications(prev => {
				const item = prev.find(i => i.notification.id === notificationId);
				if (item && !item.notification.isRead) {
					item.notification.isRead = 1;

					setUnreadNotifications(prev => prev - 1);
				}

				return [...prev];
			});
		},
		[connection, showError]
	);

	useEffect(() => {
		const newConnection = new HubConnectionBuilder()
			.withUrl('/hubs/notifications')
			.withAutomaticReconnect([1000, 2000, 3000])
			.build();
		newConnection.onclose(() => {
			setConnected(null);
		});
		setConnection(newConnection);
		if (!Push.Permission.has()) {
			Push.Permission.request();
		}
		Push.config({serviceWorker: '/serviceWorker.js'});
	}, []);

	useEffect(() => {
		if (!connection) {
			return;
		}
		connection.on('ReceiveNotification', message => {
			browserNotify(message, p, userId, setNotificationIsRead);
			let unreadNumber = 0;
			setNotifications(prev => {
				const newItems = prev.map(p => {
					if (
						p.notification.productId === message.notification.productId &&
						!p.notification.isRead &&
						message.notificationGroup?.some(id => p.notification.notificationTypeId === id)
					) {
						unreadNumber = unreadNumber - 1;
						p.notification.isRead = true;
					}
					return p;
				});
				return [message, ...newItems];
			});
			setUnreadNotifications(prev => prev + unreadNumber + 1);
		});
		connection.on('ReceiveAllUserNotifications', message => {
			setNotifications(prev => [...prev, ...message.notifications]);
			setPage(prev => prev + 1);
			setLoading(false);
			setUnreadNotifications(message.totalUnreadNotifications);
			if (message.notifications.length < 5) {
				setGetAllUserNotificationsEnabled(false);
			}
		});
		connection.on('ReceiveCreatedTimeAgo', message => {
			setNotifications(prev =>
				prev.map(n => ({...n, createdTimeAgo: message.find(m => m.id === n.notification.id)?.createdTimeAgo}))
			);
		});
		connection.on('ReceiveDeletedNotifications', message => {
			setNotifications(prev => [...prev.filter(p => !message.includes(p.notification.id))]);
		});
		connection.on('ReceiveUnreadCount', message => {
			setUnreadNotifications(message);
		});
	}, [userId, p, setNotificationIsRead, showError, connection]);

	useEffect(() => {
		const sendRequest = () =>
			connection
				.send(
					'GetCreatedTimeAgo',
					notifications.map(n => n.notification.id)
				)
				.catch(err => showError(err));
		if (isOpenNotifications && connection && notifications.length > 0 && intervalRef.current === null && !loading) {
			if (!initialDrawerOpenRequestSent.current) {
				sendRequest();
				initialDrawerOpenRequestSent.current = true;
			}
			intervalRef.current = setInterval(() => {
				sendRequest();
			}, 1000 * 60);
		}
		if (!isOpenNotifications) {
			initialDrawerOpenRequestSent.current = false;
		}
		return () => {
			clearInterval(intervalRef.current);
			intervalRef.current = null;
		};
	}, [isOpenNotifications, connection, notifications, showError, loading]);

	useEffect(() => {
		const establishConnection = () => {
			connection
				.start()
				.then(() => {
					connection.send('GetAllUserNotifications', 1).catch(err => showError(err));
					setConnected(true);
				})
				.catch(() => {
					showError(p.tt('connection failed'));
					setConnected(false);
				});
		};

		if (connection && connected === null) {
			establishConnection();
		}
		return () => {
			if (connection && connected) {
				connection.stop();
				setConnected(null);
			}
		};
	}, [connection, p, showError, connected]);

	useEffect(() => {
		if (!connected) {
			setPage(1);
			setNotifications([]);
		}
	}, [connected]);
	const handleScroll = e => {
		if (!getAllUserNotificationsEnabled || loading) {
			return;
		}
		if (e.target.scrollHeight - 40 <= e.target.clientHeight + e.target.scrollTop) {
			setLoading(true);
			connection.send('GetAllUserNotifications', page).catch(err => showError(err));
		}
	};

	const handleRestoreClick = () => {
		checkToken()
			.then(() => {
				setConnected(null);
			}, [])
			.catch(() => {
				setConnected(false);
			});
	};

	return (
		<>
			<Tooltip title={p.tc('notifications')}>
				<TooltipComponent
					connected={connected}
					setOpenNotifications={setOpenNotifications}
					classes={classes}
					unreadNotifications={unreadNotifications}
					onClick={handleRestoreClick}
				/>
			</Tooltip>
			<Drawer
				anchor="right"
				classes={{paper: `${classes.drawerPaper} primaryScroll`}}
				ModalProps={{keepMounted: true}}
				onClose={() => {
					setOpenNotifications(false);
				}}
				open={isOpenNotifications}
				variant="temporary"
				PaperProps={{onScroll: handleScroll}}
			>
				<Paper square className={classes.logoPaper}>
					<MessageIcon className={classes.messageIcon} />
					<Typography variant="h6" className={classes.noPadding}>
						{p.tc('notifications')}
					</Typography>
					<IconButton className={classes.closeNotifications} size="medium" onClick={() => setOpenNotifications(false)}>
						<ClearIcon />
					</IconButton>
				</Paper>
				<div>
					{notifications.map((item, index) => (
						<NotificationCard
							key={index}
							item={item}
							classes={classes}
							p={p}
							connection={connection}
							showError={showError}
							setNotifications={setNotifications}
							setUnreadNotifications={setUnreadNotifications}
							setNotificationIsRead={setNotificationIsRead}
						/>
					))}
				</div>
				{getAllUserNotificationsEnabled && (
					<div style={{visibility: loading ? 'visible' : 'hidden'}} className={classes.loadingContainer}>
						{loading && <CircularProgress size={30} />}
					</div>
				)}
			</Drawer>
		</>
	);
};

Notifications.propTypes = {
	isOpenNotifications: PropTypes.bool,
	setOpenNotifications: PropTypes.func,
	p: PropTypes.object,
	userId: PropTypes.number,
};

NotificationCard.propTypes = {
	item: PropTypes.object,
	classes: PropTypes.object,
	p: PropTypes.object,
	connection: PropTypes.object,
	showError: PropTypes.func,
	setNotifications: PropTypes.func,
	setUnreadNotifications: PropTypes.func,
	setNotificationIsRead: PropTypes.func,
};
TooltipComponent.propTypes = {
	connected: PropTypes.bool,
	setOpenNotifications: PropTypes.func,
	classes: PropTypes.object,
	unreadNotifications: PropTypes.number,
	onClick: PropTypes.func,
};

function mapStateToProps(state) {
	const {userId} = state.auth;
	return {userId};
}
export default connect(mapStateToProps)(Notifications);
