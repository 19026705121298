import {createStyles, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme =>
	createStyles({
		'@global': {
			'*': {
				boxSizing: 'border-box',
				margin: 0,
				padding: 0,
			},
			html: {
				'-webkit-font-smoothing': 'antialiased',
				'-moz-osx-font-smoothing': 'grayscale',
				width: '100%',
			},
			body: {
				width: '100%',
			},
			'.primaryScroll': {
				overflowY: 'auto !important',
				'&::-webkit-scrollbar': {
					width: '8px',
					backgroundColor: theme.palette.primary.outline,
				},
				'&::-webkit-scrollbar-thumb': {
					backgroundColor: theme.palette.primary.main,
					borderRadius: '10px',
				},
			},
			'#root': {
				width: '100%',
			},
			'.jp-primary-fill': {
				fill: theme.palette.primary.main,
			},
			'.jp-secondary-fill': {
				fill: theme.palette.background.dark,
			},
			'.jp-primary-stroke': {
				fill: 'none',
				stroke: theme.palette.primary.main,
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
			},
			'.jp-secondary-stroke': {
				fill: 'none',
				stroke: theme.palette.background.dark,
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
			},
			'.jp-no-fill': {
				fill: 'none',
			},
			'.jp-white-fill': {
				fill: '#fff',
			},
			'.jp-gray-fill': {
				fill: theme.palette.icons.gray,
			},
			'.jp-viber-purple-fill': {
				fill: theme.palette.icons.viberPurple,
			},
			'.jp-whatsapp-green-fill': {
				fill: theme.palette.icons.whatsappGreen,
			},
			'.jp-facebook-blue-fill': {
				fill: theme.palette.icons.facebookBlue,
			},
			'.jp-secondary-gray': {
				fill: theme.palette.secondary.gray,
			},
			'.jp-background-default': {
				fill: theme.palette.background.default,
			},
			'.multi-switch-container': {
				width: '124px !important',
				zIndex: 1,
			},
			'.multi-switch-content': {
				lineHeight: '20px !important',
			},
			'.multi-switch-handle': {
				top: 0,
				width: '42px !important',
			},
			'@keyframes increaseDiv': {
				'0%': {
					width: 0,
				},
			},
			'.imageRatio4to3': {
				width: '100%',
				position: 'relative',
				background: 'url() 50% 50% no-repeat',
				backgroundSize: 'contain',
				backgroundClip: 'content-box',
				'&:before': {
					content: `''`,
					display: 'block',
					paddingTop: '75%',
				},
			},
		},
	})
);

const GlobalStyles = () => {
	useStyles();

	return null;
};

export default GlobalStyles;
