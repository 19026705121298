import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import MultiSwitch from 'react-multi-switch-toggle';
import {
	IconButton,
	Tooltip,
	DialogContentText,
	DialogContent,
	DialogActions,
	Dialog,
	MenuItem,
	Menu,
	Button,
	ListItemIcon,
} from '@material-ui/core';
import {ReactComponent as ProfileIcon} from '../../images/topAppBar/app_bar_profile.svg';
import {ReactComponent as Logout} from '../../images/account/account_logout.svg';
// import {ReactComponent as ProfileAccount} from '../../images/account/account_profile.svg';
import {useDispatch} from 'react-redux';
import {setLanguage} from '../../actions/language/index';
import {signOut} from '../../actions/authActions';
import DialogTitle from '../dialogElements/DialogTitle';

const useStyles = makeStyles(theme => ({
	iconHeight: {
		height: '28px',
		fill: theme.palette.primary.main,
	},
	buttonIcon: {
		width: '45px',
		height: '45px',
	},
}));

const Profile = ({signOut, p}) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const dispatch = useDispatch();
	const languages = ['lt', 'en', 'ru'];
	const history = useHistory();

	const isMenuOpen = Boolean(anchorEl);
	const selectedLanguage = localStorage.getItem('language');

	const handleProfileMenuOpen = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const [openDialog, setOpenDialog] = useState(false);

	const handleDialog = () => {
		setOpenDialog(!openDialog);
	};

	const onToggle = selectedItem => {
		setLanguage(languages[selectedItem], dispatch);
		history.go(0);
	};

	const menuId = 'primary-profile-menu';
	const profileMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
			id={menuId}
			keepMounted
			getContentAnchorEl={null}
			transformOrigin={{vertical: 'top', horizontal: 'center'}}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			<MenuItem>
				<MultiSwitch
					texts={['LT', 'EN', 'RU']}
					selectedSwitch={languages.findIndex(x => x === selectedLanguage)}
					bgColor={'#ededed'}
					fontColor={'black'}
					selectedFontColor={'black'}
					selectedSwitchColor={'#33bba4'}
					eachSwitchWidth={40}
					onToggleCallback={onToggle}
					height={'25px'}
					fontSize={'13px'}
					borderWidth={'0'}
				></MultiSwitch>
			</MenuItem>
			{/* <MenuItem onClick={handleMenuClose}>
				<ListItemIcon>
					<ProfileAccount className={classes.iconHeight} />
				</ListItemIcon>
				{p.tc('profile')}
			</MenuItem> */}
			<MenuItem
				onClick={() => {
					handleMenuClose();
					handleDialog();
				}}
			>
				<ListItemIcon>
					<Logout className={classes.iconHeight} />
				</ListItemIcon>
				{p.tc('log out')}
				<Dialog open={openDialog} onClick={handleDialog}>
					<DialogTitle title={p.tt('confirm logout')} handleDialog={handleDialog} />
					<DialogContent>
						<DialogContentText>{p.tc('are you sure you want to logout?')}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => {
								signOut();
								handleDialog();
							}}
							color="primary"
						>
							{p.tu('ok')}
						</Button>
						<Button onClick={handleDialog} color="primary" autoFocus>
							{p.tc('cancel')}
						</Button>
					</DialogActions>
				</Dialog>
			</MenuItem>
		</Menu>
	);

	return (
		<>
			<Tooltip title={p.tc('profile')}>
				<IconButton
					edge="end"
					className={classes.buttonIcon}
					aria-label="account of current user"
					aria-controls={menuId}
					aria-haspopup="true"
					onClick={handleProfileMenuOpen}
					color="inherit"
				>
					<ProfileIcon className={classes.iconHeight} />
				</IconButton>
			</Tooltip>
			{profileMenu}
		</>
	);
};

const mapStateToProps = state => {
	return {isSignedIn: state.auth.isSignedIn};
};

Profile.propTypes = {
	signOut: PropTypes.func,
	p: PropTypes.object,
};

export default connect(mapStateToProps, {signOut})(Profile);
