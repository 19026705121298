import {encode as base64Encode, decode as base64Decode} from 'base-64';
import {encode as encodeUtf8, decode as decodeUtf8} from 'utf8';
import {removeEmptyArraysUndefined} from './prettify';
/**
 *
 * @param {*} any
 * @returns {string} base64 of json of passed parameter.
 */
export const toBase64Json = any => {
	return base64Encode(encodeUtf8(JSON.stringify(removeEmptyArraysUndefined(any || ''))));
};

/**
 *
 * @param {string} base64Json
 * @param {*} defaultObject
 * @returns
 */
export const decodeBase64Json = (base64Json, defaultObject = null) => {
	defaultObject = defaultObject === null ? {} : defaultObject;
	return base64Json ? JSON.parse(decodeUtf8(base64Decode(base64Json))) || defaultObject : defaultObject;
};

export const isValidBase64Document = (base64Str, expectedFileType) => {
	const fileSignatures = {
		pdf: '25504446',       // %PDF
		docx: '504B0304',      // ZIP (for DOCX, XLSX, PPTX)
		xlsx: '504B0304',      // ZIP (for DOCX, XLSX, PPTX)
		pptx: '504B0304',      // ZIP (for DOCX, XLSX, PPTX)
		png: '89504E47',       // PNG
		jpg: 'FFD8FF',         // JPEG
		gif: '47494638',       // GIF
		txt: 'EFBBBF'          // UTF-8 BOM for TXT
	};

	try {
		// Check if the string matches the base64 pattern
		const base64Pattern = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
		if (!base64Pattern.test(base64Str)) {
			return false;
		}

		// Decode base64 string
		const binaryStr = atob(base64Str);
		const len = binaryStr.length;
		const bytes = new Uint8Array(len);

		for (let i = 0; i < len; i++) {
			bytes[i] = binaryStr.charCodeAt(i);
		}

		// Convert first few bytes to hex string
		const hexStr = Array.from(bytes)
			.slice(0, 4)
			.map(byte => byte.toString(16).padStart(2, '0'))
			.join('')
			.toUpperCase();

		// Check file signature
		const expectedSignature = fileSignatures[expectedFileType.toLowerCase()];
		return hexStr.startsWith(expectedSignature);
	} catch (e) {
		// If an error occurs during decoding, it's not a valid base64 document
		return false;
	}
};