export const NOT_CHANGEABLE_ORDER_STATUSES = [7, 8, 9, 10];

export const SHIPMENT_COURIER_STATUS_ID_AWAITING_PAYMENT = 1;
export const SHIPMENT_COURIER_STATUS_ID_PAID = 2;
export const SHIPMENT_COURIER_STATUS_ID_COMPLETED = 3;
export const SHIPMENT_COURIER_STATUS_ID_DISPATCHED = 4;
export const SHIPMENT_COURIER_STATUS_ID_COLLECTED = 5;
export const SHIPMENT_COURIER_STATUS_ID_DELIVERED = 6;
export const SHIPMENT_COURIER_STATUS_ID_RETURNING = 7;
export const SHIPMENT_COURIER_STATUS_ID_RETURNED = 8;
export const SHIPMENT_COURIER_STATUS_ID_REFUNDED = 9;
export const SHIPMENT_COURIER_STATUS_ID_PARTIALY_REFUNDED = 10;
export const SHIPMENT_COURIER_STATUS_ID_CANCELLED = 11;

export const shipmentStatuses = Object.freeze({
    awaitingPayment: {id: 1},
    paid: {id: 2},
    labelPrinted: {id: 3},
    dispatched: {id: 4},
    collected: {id: 5},
    delivered: {id: 6},
    returning: {id: 7},
    returned: {id: 8},
    refunded: {id: 9},
    partiallyRefunded: {id: 10},
    canceled: {id: 11},
    picked: {id: 12},
    packaging: {id: 13},
    weighing: {id: 14},
    weighed: {id: 15},
    awaitingCollection: {id: 16},
    delivering: {id: 17},
    deliveryIssues: {id: 18},
    packed: {id: 19},
    withIssues: {id: 20},
    readyForPickUp: {id: 21},
});

export const ADMIN_ROLE_ID = 1;
export const USER_ROLE_ID = 2;
export const PACKER_ROLE_ID = 4;
export const PICKER_ROLE_ID = 5;

export const partStatuses = {
    incomplete: {id: 12},
    active: {id: 14},
    sold: {id: 16},
};

export const orderProductStatuses = {
    picked: {id: 1},
    packaging: {id: 2},
    packed: {id: 3},
    missing: {id: 4},
    broken: {id: 5},
    missingPieces: {id: 6}
};

export const homeRoutes = {
    [ADMIN_ROLE_ID]: '/',
    [USER_ROLE_ID]: '/',
    [PACKER_ROLE_ID]: '/packing-orders',
};
