import React, {useCallback, useMemo} from 'react';
import {useSnackbar} from 'notistack';
import {Slide, CircularProgress} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import {isCancel} from 'axios';

const Alert = React.forwardRef((props, ref) => (
	<MuiAlert elevation={6} variant="filled" {...props} style={{whiteSpace: 'pre-wrap'}} ref={ref} />
));

function transitionLeft(props) {
	return <Slide {...props} direction="right" />;
}

export function useCustomSnackbar() {
	const {enqueueSnackbar, closeSnackbar} = useSnackbar();

	const showSnackbar = useCallback(
		(text, severity, autoHideDuration = undefined) => {
			enqueueSnackbar(text, {
				autoHideDuration: autoHideDuration !== undefined ? autoHideDuration : 6000,
				TransitionComponent: transitionLeft,
				content: (key, message) => (
					<Alert
						onClose={() => {
							closeSnackbar(key);
						}}
						severity={severity}
						id={key}
					>
						{message}
					</Alert>
				),
			});
		},
		[closeSnackbar, enqueueSnackbar]
	);

	const showError = useCallback(
		(text, hideDuration = undefined) => {
			showSnackbar(text?.message || text, 'error', hideDuration);
		},
		[showSnackbar]
	);
	const showResponseError = useCallback(
		(error, p) => {
			const message = error?.response?.data?.title?.toLowerCase() || error?.response?.data?.toLowerCase() || 'error';
			if (!isCancel(error)) {
				showSnackbar(
					message
						.split('\r\n')
						.map(text => (p && p.tc(text)) || text)
						.join('\n'),
					'error'
				);
			}
		},
		[showSnackbar]
	);

	const showLoading = useCallback(
		loadingText => {
			const loadingSnackbarKey = enqueueSnackbar(loadingText, {
				persist: true,
				content: key => (
					<Alert
						onClose={() => {
							closeSnackbar(key);
						}}
						icon={false}
						severity="info"
						id={key}
					>
						<CircularProgress size={20} style={{marginRight: 8}} />
						{loadingText}
					</Alert>
				),
			});
			return loadingSnackbarKey;
		},
		[enqueueSnackbar, closeSnackbar]
	);

	const closeLoading = useCallback(
		loadingSnackbarKey => {
			closeSnackbar(loadingSnackbarKey);
		},
		[closeSnackbar]
	);

	const showSuccess = useCallback(
		text => {
			showSnackbar(text?.message || text, 'success');
		},
		[showSnackbar]
	);

	const showWarning = useCallback(
		text => {
			showSnackbar(text?.message || text, 'warning');
		},
		[showSnackbar]
	);

	const showInfo = useCallback(
		text => {
			showSnackbar(text?.message || text, 'info');
		},
		[showSnackbar]
	);

	return useMemo(() => {
		return {
			showSnackbar,
			showError,
			showSuccess,
			showWarning,
			showInfo,
			showResponseError,
			showLoading,
			closeLoading,
		};
	}, [showError, showInfo, showResponseError, showSnackbar, showSuccess, showWarning, showLoading, closeLoading]);
}
