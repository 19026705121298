import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

const GuestGuard = props => {
	if (props.isSignedIn) {
		return <Redirect to={props.pageUrl} />;
	}
	return props.children;
};

const mapStateToProps = state => {
	return {isSignedIn: state.auth.isSignedIn, pageUrl: state.auth.pageUrl};
};
GuestGuard.propTypes = {
	isSignedIn: PropTypes.bool,
	children: PropTypes.object.isRequired,
	pageUrl: PropTypes.string,
};
export default connect(mapStateToProps)(GuestGuard);
